import Box from "@mui/material/Box";
import "./Video.css";

function Video() {
  return (
    <Box
      sx={{
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className="video-wrapper" style={{ pointerEvents: "none" }}>
        <video
          autoPlay
          muted
          loop
          playsInline
          id="myVideo"
          className="no-border"
          preload="auto"
          style={{ objectFit: 'cover' }}
        >
          <source src="video2.mp4" type="video/mp4" />
        </video>
      </div>
    </Box>
  );
}

export default Video;
