import React from "react";

const SplatViewer = () => {
  return (
    <iframe
      src="point_cloud-viewer.html"
      width={"100%"}
      height={"100%"}
      style={{ border: "none" }}
      title="Splat Viewer"
    />
  );
};

export default SplatViewer;
 