import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";
import GitHubIcon from "@mui/icons-material/GitHub";
import Fade from "@mui/material/Fade";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import anime from "animejs";
// import Avatar from "@mui/material/Avatar";
import "./Bio.css";

function Bio() {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("lg"));

  // animation code
  useEffect(() => {
    var textWrapper = document.querySelector(".ml11 .letters");
    textWrapper.innerHTML = textWrapper.textContent.replace(
      /([^\x00-\x80]|\w|,|`|')/g,
      "<span class='letter'>$&</span>"
    );
    anime
      .timeline({})
      .add({
        // scale line
        targets: ".ml11 .line",
        scaleY: [0, 1],
        opacity: [0.5, 1],
        easing: "easeOutExpo",
        duration: 700,
      })
      .add({
        // move line
        targets: ".ml11 .line",
        translateX: [
          0,
          document.querySelector(".ml11 .letters").getBoundingClientRect()
            .width + 10,
        ],
        easing: "easeOutExpo",
        duration: 700,
        delay: 100,
      })
      .add(
        {
          // move text
          targets: ".ml11 .letter",
          opacity: [0, 1],
          easing: "easeOutExpo",
          duration: 600,
          delay: (el, i) => 34 * (i + 1),
        },
        "-=775"
      );
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: 1,
        width: { xs: "75%", md: "55%", xl: "30%" },
      }}
      pt={{ xs: 0, md: 0 }}
      px={{ xs: 2, md: 7 }}
    >
      <Box
        sx={{
          display: { xs: "flex" },
          flexDirection: "column",
          pb: 2,
          position: 'absolute',
          top: 0,
          left: { xs: '5%', sm: '27%', md: '72%' },
        }}
      >
        {/* <Fade in={true} {...{ timeout: 2000 }}> */}
          {/* <Avatar
            alt="Pic"
            src={process.env.PUBLIC_URL + "/pic1.jpg"}
            sx={{ width: 150, height: 150 }}
          /> */}
          {/* <Particles /> */}
        {/* </Fade> */}
      </Box>
      <Box
        sx={{
          // height: { xs: 240, sm: 240, md: 260 },
        }}
      />
      <h2 style={{ margin: 10}} className="ml11">
        <span className="text-wrapper">
          <span className="line line1"></span>
          <span className="letters">Hi, I'm Duc ✌️</span>
        </span>
      </h2>
      <Fade
        in={true}
        style={{ transitionDelay: "1500ms" }}
        {...{ timeout: 2000 }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          align={isMobile ? "center" : "center"}
          style={{ fontWeight: 'bold', color: '#455a64' }}
        >
          I'm a tech enthusiast, particularly fascinated by everything surrounding Generative AI, 3D Computer Vision, and Extended Reality (XR).
        </Typography>
      </Fade>
      <Fade
        in={true}
        style={{ transitionDelay: "2000ms" }}
        {...{ timeout: 2000 }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            gutterBottom
            align={isMobile ? "center" : "center"}
            color="#3F4C64"
          >
            I have studied Computer Science in
              London, Paris, Munich and Berlin
            and currently work as a Graduate Research Assistant at{" "}
            <Link
              target="_blank"
              href="https://www.hhi.fraunhofer.de/en/departments/vit.html"
              underline="none"
              className="hoverLink"
              color = "#0e5fa1"
              sx={{ fontWeight: 'bold' }}
            >
              Fraunhofer Heinrich Hertz Institute
            </Link>
            , Berlin.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
            justifyContent={{ xs: "center" }}
          >
            <IconButton
              size="medium"
              sx={{ color: "white" }}
              onClick={() => {
                window.open("https://www.linkedin.com/in/duc-nguy");
              }}
            >
              <LinkedInIcon sx={{ fontSize: 30, color: "white" }} />
            </IconButton>
            <IconButton
              size="medium"
              sx={{ color: "white" }}
              onClick={() => {
                window.open("mailto:ui7da99jbs@privaterelay.appleid.com");
              }}
            >
              <MailIcon sx={{ fontSize: 30, color: "white" }} />
            </IconButton>
            <IconButton
              size="medium"
              sx={{ color: "white" }}
              onClick={() => {
                window.open("https://github.com/duc-ng");
              }}
            >
              <GitHubIcon sx={{ fontSize: 30, color: "white" }} />
            </IconButton>
          </Box>
        </Box>
      </Fade>
    </Box>
  );
}

export default Bio;
