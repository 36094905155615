import "./App.css";
import Bio from "./Bio";
import Footer from "./Footer";
import Grid from "@mui/material/Grid";
import Video from "./Video";
import SplatViewer from "./SplatViewer";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";

function App() {
  const isMobile = useMediaQuery("(max-width: 900px)"); 

  const [isFirefox, setIsFirefox] = useState(false);

  useEffect(() => {
    const isFirefoxBrowser = navigator.userAgent.toLowerCase().includes('firefox');
    setIsFirefox(isFirefoxBrowser);
  }, []);

  if (isFirefox) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <h1>Sorry, this page is not supported in Firefox.</h1>
        <p>Please use another browser like Chrome or Safari to access the app.</p>
      </div>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <Grid container spacing={0} justifyContent="center">
          {!isMobile && ( 
            <Grid
              item
              xs={12}
              md={6}
              sx={{ backgroundColor: "white", height: "100vh" }}
            >
              <SplatViewer />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center", 
              height: "100vh",
              backgroundColor: "#bdbdbd",
            }}
          >
            <Video />
            <Bio />
            <Footer />
          </Grid>
        </Grid>
      </header>
    </div>
  );
}

export default App;
